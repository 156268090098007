import type { EventBusEvent } from '@/features/core/event-bus';

export class PickingStartedEvent implements EventBusEvent {
  name = 'PickingStartedEvent';
}

export class PickingContinuedEvent implements EventBusEvent {
  name = 'PickingContinuedEvent';
}

export class PickingCompletedEvent implements EventBusEvent {
  name = 'PickingCompletedEvent';
}

export class HandoverStartedEvent implements EventBusEvent {
  name = 'HandoverStartedEvent';
}

export class HandoverCompletedEvent implements EventBusEvent {
  name = 'HandoverCompletedEvent';
}

export class HandoverRestagedEvent implements EventBusEvent {
  name = 'HandoverRestagedEvent';
}
